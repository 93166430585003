import "metismenu/dist/metisMenu.min";
import "jquery-slimscroll/jquery.slimscroll.min";

$(document).ready(function () {
    // Add body-small class if window less than 768px
    if ($(window).width() < 769) {
        $("body").addClass("body-small");
    } else {
        $("body").removeClass("body-small");
    }

    // MetisMenu
    $("#side-menu").metisMenu();

    // Collapse ibox function
    $(".collapse-link").on("click", function (e) {
        e.preventDefault();
        var ibox = $(this).closest("div.ibox");
        var button = $(this).find("i");
        var content = ibox.children(".ibox-content");
        content.slideToggle(200);
        button.toggleClass("fa-chevron-up").toggleClass("fa-chevron-down");
        ibox.toggleClass("").toggleClass("border-bottom");
        setTimeout(function () {
            ibox.resize();
            ibox.find("[id^=map-]").resize();
        }, 50);
    });

    // Close ibox function
    $(".close-link").on("click", function (e) {
        e.preventDefault();
        var content = $(this).closest("div.ibox");
        content.remove();
    });

    // Fullscreen ibox function
    $(".fullscreen-link").on("click", function (e) {
        e.preventDefault();
        var ibox = $(this).closest("div.ibox");
        var button = $(this).find("i");
        $("body").toggleClass("fullscreen-ibox-mode");
        button.toggleClass("fa-expand").toggleClass("fa-compress");
        ibox.toggleClass("fullscreen");
        setTimeout(function () {
            $(window).trigger("resize");
        }, 100);
    });

    // Close menu in canvas mode
    $(".close-canvas-menu").on("click", function (e) {
        e.preventDefault();
        $("body").toggleClass("mini-navbar");
        SmoothlyMenu();
    });

    // Open close small chat
    $(".open-small-chat").on("click", function (e) {
        e.preventDefault();
        $(this).children().toggleClass("fa-comments").toggleClass("fa-times");
        $(".small-chat-box").toggleClass("active");
    });

    // Minimalize menu
    $(".navbar-minimalize").on("click", function (event) {
        event.preventDefault();
        $("body").toggleClass("mini-navbar");
        SmoothlyMenu();
    });
});

// Minimalize menu when screen is less than 768px
$(window).bind("resize", function () {
    const body = $("body");
    if (window.innerWidth < 769) {
        body.addClass("body-small");
    } else {
        body.removeClass("body-small");
    }
});

// Fixed Sidebar
$(window).bind("load", function () {
    if ($("body").hasClass("fixed-sidebar")) {
        $(".sidebar-collapse").slimScroll({
            height: "100%",
            railOpacity: 0.9,
        });
    }
});

function SmoothlyMenu() {
    const body = $("body");
    if (!body.hasClass("mini-navbar") || body.hasClass("body-small")) {
        // Hide menu in order to smoothly turn on when maximize menu
        $("#side-menu").hide();
        // For smoothly turn on menu
        setTimeout(function () {
            $("#side-menu").fadeIn(400);
        }, 200);
    } else if ($("body").hasClass("fixed-sidebar")) {
        $("#side-menu").hide();
        setTimeout(function () {
            $("#side-menu").fadeIn(400);
        }, 100);
    } else {
        // Remove all inline style from jquery fadeIn function to reset menu state
        $("#side-menu").removeAttr("style");
    }
}
